import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

const SeeAllNews = () => (
  <Link to="/company/releases">
    <div className="flex items-center">
      <StaticImage src="../../../../images/arrow-left.svg" className="w-4 md:w-8" alt="arrow back" />
      <div className="pl-4 font-bold md:pl-8 text-blue">See all New and Press Releases</div>
    </div>
  </Link>
);

const TrustleAppointsGantRedmonAsChiefExecutiveOfficerToDriveNextLevelOfGrowth = () => {
  const releaseTitle = 'Trustle Appoints Gant Redmon as Chief Executive Officer to Drive Next Level of Growth';
  return (
    <Layout
      seo={
        <Seo
          title="Newsroom | Trustle Appoints Gant Redmon"
          description="Trustle, provider of innovative cloud access management solutions, announced the appointment of Gant Redmon as Chief Executive Officer to Drive Next Level of Growth."
        />
      }
    >
      <section className="pb-16 bg-gradient-to-b from-gray-200 via-white to-gray-200 xl:pb-64">
        <div className="flex flex-col py-16 lg:pb-32 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
          <SectionIntro
            isPageHeader
            hero="Company"
            title="Newsroom"
            description={`Stay up-to-date with everything that is happening with the company.</br>`}
          />

          <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
            <div className="py-16">
              <SeeAllNews />
            </div>
            <div className="mx-8">
              <div className="mb-8 text-xl font-bold text-left md:text-2xl">{releaseTitle}</div>
              <p className="text-xl text-left">
                Founder Emiliano Berenbaum transitions to Chief Technology Officer; Carl Herberger, CEO of Corero
                Network Security appointed to the Board<br></br>
              </p>
              <p className="text-lg text-left">
                <br></br>
                WALNUT CREEK, Calif., Nov. 12, 2024 --{' '}
                <a
                  href="https://www.trustle.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold hover:underline"
                >
                  Trustle
                </a>
                , the seamless and secure access management provider, today named{' '}
                <a
                  href="https://www.linkedin.com/in/gant-redmon/"
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold hover:underline"
                >
                  Gant Redmon
                </a>
                , former CEO of Cloud FastPath and Hopara, Inc., as its new Chief Executive Officer and Board Director.
                Gant joins Trustle to lead day-to-day operations and provide strategic direction and vision for the
                company alongside Founder{' '}
                <a
                  href="https://www.linkedin.com/in/eberenb/"
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold hover:underline"
                >
                  Emiliano Berenbaum
                </a>
                , who steps into the role of Chief Technology Officer and remains a Board Director. Trustle also
                announced the appointment of{' '}
                <a
                  href="https://www.linkedin.com/in/carlherberger/"
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold hover:underline"
                >
                  Carl Herberger
                </a>
                , CEO of Boston cybersecurity company Corero Network Security, to Trustle’s Board.
                <br></br>
                <br></br>
                Redmon brings a strong track record of driving growth and innovation at cutting-edge technology
                companies to the organization. Prior to Trustle, he served as CEO of Hopara. Before Hopara, Redmon
                successfully led Cloud FastPath, a SaaS cloud-based migration services company, through its acquisition
                by Box, Inc. Earlier in his career, Gant served on Resilient Systems’ executive leadership team, helping
                to prepare and position the company for its sale to IBM. Gant’s expertise spans cloud platforms, data
                visualization, security, and privacy.
                <br></br>
                <br></br>
                "I am thrilled to join Trustle and lead such a dynamic and forwardthinking company," said Gant Redmon,
                CEO of Trustle. "Trustle’s commitment to empowering businesses with its granular, contextbased access
                management platform is exciting, and I look forward to working with this talented team to build on that
                legacy, deliver even greater value to our customers, and scale the company to new heights."
                <br></br>
                <br></br>
                “Solid teams are stronger than individuals,” said Emiliano Berenbaum, Trustle’s Founder and Chief
                Technology Officer. “Gant and I are excited to work together to transform the industry by offering
                frictionless, just-in-time access. Gant will oversee Trustle’s daily operations and GTM strategies, and
                together, we will work to drive additional growth for the company.”
                <br></br>
                <br></br>
                “Having led the company’s Seed round, I am excited for Gant to join Emiliano and the team in building on
                Trustle’s momentum and expanding the reach of its revolutionary platform,” said Rick Grinnell, Founder
                and Managing Partner at Glasswing Ventures and Trustle board member. “Having previously worked with Gant
                while I was an investor and board member at Resilient Systems, I have seen firsthand his management
                capabilities. Gant is the ideal leader to further the company’s scale and success.”
                <br></br>
                <br></br>
                Carl Herberger, CEO of Corero Network Security, has been appointed to Trustle’s Board, bringing insights
                on cyber threats and security solutions gleaned during his over 25 years of cybersecurity leadership
                experience.
                <br></br>
                <br></br>
                <b>About Trustle</b>
                <br></br>
                Trustle simplifies needs-based access control and enables a more secure and efficient cloud environment.
                Its innovative platform ensures that organizations can easily grant, track, and audit access through
                just-in-time access controls. This added layer of security enables businesses to protect their
                resources, and streamline compliance as well as provide critical insights and recommendations about
                license utilization and risk exposure. Learn more about Trustle at https://www.trustle.com/.
                <br></br>
                <br></br>
                <b>Contact:</b>
                <br></br>
                Gant Redmon, CEO <br></br>
                gant@trustle.com
              </p>
            </div>
             
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TrustleAppointsGantRedmonAsChiefExecutiveOfficerToDriveNextLevelOfGrowth;
